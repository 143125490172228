import React, { useContext } from "react";
import styled from "styled-components";
import PreviewContext from "../../components/Layout/PreviewContext";
import SocialShare from "../../components/SocialShare/SocialShare";
import { HTMLContent } from "../../components/Content";
import SplitPageContent from "../../components/SplitPageContent/SplitPageContent";
import PageSection from "../../components/PageSection/PageSection";
import FeaturedEvents, { StyledFeaturedEvent } from "../../components/FeaturedEvents/FeaturedEvents";
import EventHighlightSection from "../../components/EventHighlightSection/EventHighlightSection";

const AD_RECURRENCE = 7,
	AD_ITEM = "__ad__";

const ContentSection = styled(PageSection)`
	justify-content: center;
	${(props) => props.contentSectionStyles}
`;

const IndexFeaturedEvent = styled(StyledFeaturedEvent)`
	width: 42%;
  min-width: 300px;
`;

const renderContentItems = ({ page, ContentItemComponent, AdContentItemComponent }) => {
	const items = page.items.slice();

	if (AdContentItemComponent) {
		const indexMod = (AD_RECURRENCE - 1);
		let nextIndex = indexMod;

		while (nextIndex < items.length) {
			items.splice(nextIndex, 0, AD_ITEM);
			nextIndex += indexMod;
		}

		nextIndex -= indexMod;
		//ADD ONE LAST IN CASE VERY SHORT LIST OF ITEMS OR HASNT BEEN AN AD TOWARD THE END
		if (nextIndex === 0 || (items.length - nextIndex) > (indexMod / 2)) {
			items.splice(items.length, 0, AD_ITEM);
		}
	}

	return items.map((item, index) => item === AD_ITEM ?
		<AdContentItemComponent key={`${AD_ITEM}-${index}`} /> :
		<ContentItemComponent key={item.slug} item={item} />);
};

const GenericContentIndexPage = (props) => {
	const isPreview = useContext(PreviewContext);

	const { page } = props;

	const options = props.options || {};

	return (<SplitPageContent
		title={page.title}
		subTitle={page.heading}
		image={page.image}
		imageYPosition={page.imageYPosition}
		mainColor={props.mainColor || "main"}
		sections={page.sections}
		showAd={props.showAd}>

		{!isPreview && options.showSocialShare &&
			<PageSection>
				<SocialShare {...page} url={page.slug} noMargin />
			</PageSection>}

		{page.content && <PageSection>
			<HTMLContent content={page.content} />
		</PageSection>}

		{page.highlightedEvents &&
		<EventHighlightSection bottomBorder>
			<FeaturedEvents
				title={page.eventText}
				items={page.highlightedEvents}
				ItemComponent={IndexFeaturedEvent}
				page={page.slug}
			/>
		</EventHighlightSection>}

		{page.items &&
			<ContentSection contentSectionStyles={options && options.contentSectionClass}>
				{props.ItemsComponent ?
					<props.ItemsComponent items={page.items} /> :
					(props.ContentItemComponent && renderContentItems(props))}
			</ContentSection>}
	</SplitPageContent>);
};

export default GenericContentIndexPage;
