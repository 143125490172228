import React from "react";
import { graphql } from "gatsby";
import styled, { css } from "styled-components";
import { Heading } from "grommet";
import GenericContentIndexPage from "./generic/genericContentIndexTemplate";
import { transformEdgesNodes } from "../components/utils";
import PageSection from "../components/PageSection/PageSection";
import NavLink from "../components/Link/NavLink";
import withLayoutAndData from "./generic/withLayoutAndData";

const HEBREW = ["א", "ב", "ג", "ד", "ה", "ו", "ז", "ח", "ט", "י", "כ", "ל", "מ", "נ", "ס", "ע", "פ", "צ", "ק", "ר", "ש", "ת"];

const LetterSection = styled(PageSection)`
	display: block;

	a {
		margin: 6px 10px 0 0;
	}
`;

const GlossaryItems = ({ items }) => {
	const glossary =
		HEBREW.reduce((res, letter) => {
			const letterItems = items.filter((item) => item.title[0] === letter);
			return res.concat(letterItems.length ? { letter, letterItems } : []);
		}, []);

	return <div>
		{glossary.map((letter) => <LetterSection key={letter.letter}>
			<Heading level={2} size="medium">{letter.letter}</Heading>
			{letter.letterItems.map((item) =>
				<NavLink key={item.slug} to={item.slug}>{item.title}</NavLink>)}
		</LetterSection>)}
	</div>;
};

const contentSectionClass = css`
	justify-content: right;
`;

export const GloassaryIndexPage = ({ page, preview }) =>
	<GenericContentIndexPage page={page}
		ItemsComponent={GlossaryItems}
		showAd
		options={{ contentSectionClass, showSocialShare: true }}
		preview={preview} />;

export const pageQuery = graphql`
	query GlossaryIndexPageTemplate {
			markdownRemark(frontmatter: {templateKey: {eq: "glossary-index-page" } }) {
			html
			frontmatter {
			title
        		heading
		}
			fields {
			slug
		}
		...RemoteBannerImage
	}
	items: allMarkdownRemark(
			sort: {order: DESC, fields: [frontmatter___date] }
			filter: {frontmatter: {templateKey: {eq: "glossary-item-page" } } }
		) {
			edges {
				node {
					id
					fields {
						slug
					}
					frontmatter {
						title
					}
				}
			}
		}
}`;

export default withLayoutAndData(
	({ data }) => ({
		page: {
			...data.markdownRemark.frontmatter,
			...data.markdownRemark.fields,
			content: data.markdownRemark.html,
			items: transformEdgesNodes(data.items),
		}
	}),
)(GloassaryIndexPage);
