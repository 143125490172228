import React, { useContext } from "react";
import styled from "styled-components";
import { Heading } from "grommet";
import { breakpoint, border, color } from "../../theme";
import PreviewContext from "../Layout/PreviewContext";
import PageBanner from "../PageBanner/PageBanner";
import Image from "../Image/Image";
import PageLinks from "../PageLinks/PageLinks";
import AdSenseAd from "../AdNetworks/AdSenseAd";
// import type { GatsbyImage } from "../../common/types";

const SplitContainer = styled.div`
	display: flex;
	flex-direction: row;
	width: 100%;
	height: 100%;
	${border({ style: "dotted", color: "border.light" })`bottom`}
`;

const TitleSide = styled.section`
	min-width: 310px;
	display: none;
	flex-direction: column;
	/* padding: 20vh 0 0 0; */
	padding: 0;
	justify-content: start;
	align-items: center;

	${({ mainColor }) => color((mainColor || "accent-1"), "background-color")};

	${breakpoint("tablet", true)`
		display: flex;
		flex-grow: 1;
	`}
`;

const PageTitles = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
`;

const Title = styled(Heading)`
	margin-bottom: 20px;
`;

const SubTitle = styled(Heading)`
	text-align:center;
`;

const ContentSide = styled.section`
	flex-grow: 1;
	display: flex;
	flex-direction: column;
	padding: 0;

	${breakpoint("phone")`
		max-width: 100%;
	`}
`;

const ResponsivePageBanner = styled(PageBanner)`
	display: none;
	color: red;

	${breakpoint("tablet")`
		display: flex;
	`}
`;

const PageImage = styled(Image)`
	width: 100%;
	height: 200px;
	margin-bottom: 60px;
`;

const TitleSidePageLinks = styled(PageLinks)`
	${border({ style: "dotted", color: "dark-3" })`top bottom`}
	padding: 10px 0;
`;

const ResponsivePageLinks = styled(PageLinks)`
	display: none;

	${breakpoint("tablet")`
		display: flex;
	`}
`;

// type Props = {
// 	title: String,
// 	subTitle: String,
// 	image: GatsbyImage,
// 	children?: Object[],
// 	mainColor: string,
// 	imageYPosition: String,
// 	sections: string[],
// 	showAd?: boolean
// };

const SplitPageContent = (props) => {
	const { image, title, imageYPosition } = props;
	const isPreview = useContext(PreviewContext);

	return <div>
		<SplitContainer>
			<TitleSide mainColor={props.mainColor}>
				{props.image && <PageImage
					background
					yPosition={imageYPosition}
					image={image}
					alt="blog" />}

				<PageTitles>
					<Title level={1} size="medium">{title}</Title>
					<SubTitle level={2} size="medium">{props.subTitle}</SubTitle>
				</PageTitles>

				{props.sections &&
					<TitleSidePageLinks sections={props.sections} />}
			</TitleSide>

			<ContentSide>
				<ResponsivePageBanner title={title} image={image} yPosition={imageYPosition} />

				{props.sections &&
					<ResponsivePageLinks title="בעמוד:" sections={props.sections} />}

				{props.children}
			</ContentSide>
		</SplitContainer>
		{props.showAd && !isPreview &&
			<AdSenseAd slot="3928202188" format="auto" fullWidthResponsive />}
	</div>;
};

export default SplitPageContent;
