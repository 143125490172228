import React, { useLayoutEffect, useState } from "react";
import styled from "styled-components";
import { Text, Heading } from "grommet";
import { FormPrevious } from "grommet-icons";
import { color } from "../../theme";

const Container = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-items: flex-start;
`;

const Link = styled.a`
	display: flex;
	${color("dark-1")}
	font-weight: bold;
	margin-bottom: 6px;

	&:hover {
		text-decoration: underline;
	}

	svg {
		margin-left: 2px;
	}
`;

const Links = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-items: flex-start;

	${({ title }) => title ? "margin-right: 20px" : ""}
`;

const PageLinks = ({ className, title, sections }) => {
	const [links, setLinks] = useState([]);

	useLayoutEffect(() => {
		const withTitles = sections.map((s) => {
			const sectionElm = document.getElementById(s);

			return {
				id: s,
				title: sectionElm.textContent,
			};
		});

		setLinks(withTitles);
	}, []);

	return <Container className={className}>
		{title && <Heading level="2" size="small" margin={{ horizontal: "small", vertical: "xsmall" }}>{title}</Heading>}

		<Links title={title}>
			{links.map((s) =>
				<Link key={s.id} href={`#${s.id}`}>
					<FormPrevious />
					<Text size="medium">{s.title}</Text>
				</Link>)}
		</Links>
	</Container>;
};

export default PageLinks;
